
import Vue, { PropType } from 'vue';
import CustomSlider from 'vue-custom-range-slider';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';
import 'vue-custom-range-slider/dist/vue-custom-range-slider.scss';

interface RangeSliderValues {
  value: string;
  selected: boolean;
}

interface RangeSliderData {
  selectedValue: string;
  sliderValues: RangeSliderValues[];
}

export default Vue.extend({
  name: 'MoleculeRangeSlider',
  components: {
    CustomSlider,
    AtomSvgIcon,
  },
  props: {
    min: {
      type: Number as PropType<number>,
      required: true,
    },
    max: {
      type: Number as PropType<number>,
      required: true,
    },
    step: {
      type: Number as PropType<number>,
      required: true,
    },
    defaultValue: {
      type: Number as PropType<number>,
      required: false,
    },
    // To be implemented
    disabled: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
    displayValues: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: true,
    },
    increaseIcon: {
      type: String,
      default: 'icons/icon-circle-plus',
    },
    decreaseIcon: {
      type: String,
      default: 'icons/icon-circle-minus',
    },
  },
  data: (): RangeSliderData => ({
    selectedValue: '',
    sliderValues: [],
  }),
  mounted() {
    const { min, max } = this.normalizedRange();
    for (let value = min; value <= max; value += this.step) {
      this.sliderValues.push({
        value: value.toString(),
        selected: value === this.getDefaultValue(value),
      });
    }
    this.selectedValue = this.getDefaultValue(this.defaultValue).toString();
  },
  methods: {
    normalizedRange() {
      const min = this.min % this.step;
      const max = this.max % this.step;
      return { min: this.min - min, max: this.max - max };
    },
    getDefaultValue(value: number | undefined) {
      const { min, max } = this.normalizedRange();
      if (value) {
        if (min !== 0 && value <= min) return min;
        if (value >= max) return max;
        return value;
      }

      return min;
    },
    emitChange() {
      this.$emit('change', parseInt(this.selectedValue, 10));
    },
    decreaseValue(value: number) {
      const { min } = this.normalizedRange();
      const newValue = parseInt(this.selectedValue, 10) - value;
      if (newValue <= min) this.selectedValue = min.toString();
      else this.selectedValue = newValue.toString();

      this.emitChange();
    },
    increaseValue(value: number) {
      const { max } = this.normalizedRange();
      const newValue = parseInt(this.selectedValue, 10) + value;
      if (newValue >= max) this.selectedValue = max.toString();
      else this.selectedValue = newValue.toString();

      this.emitChange();
    },
  },
});
